<template>
  <div class="management-info-page">
    <bread-crumb
      v-if="modelInfo"
      :name="modelInfo.name"
    />
    <div class="page-title-row">
      <div class="title">
        {{ $t('sideNav.modelTesting') }}
      </div>
    </div>
    <div class="description">
      {{ $t('modelTesting.modelTestingDescription') }}
    </div>
    <div
      v-if="dataSourceList && dataSourceList.length > 0"
      class="info-block dataframe-selector"
    >
      <p class="dataframe-selector__title">
        {{ $t('modelTesting.testingSetting') }}
      </p>
      <div class="dataframe-selector__content">
        <div class="dataframe-selector__content-item">
          <default-select
            v-validate="'require'"
            v-model="testingTargetData.source"
            :option-list="dataSources"
            :placeholder="$t('editing.chooseDataSource')"
            :is-disabled="isLoading"
            name="dataSource"
          />
        </div>
        <div class="dataframe-selector__content-item">
          <default-select
            v-validate="'require'"
            v-model="testingTargetData.frame"
            :option-list="dataFrames"
            :is-disabled="testingTargetData.source === null || isLoading"
            :placeholder="$t('editing.chooseDataFrame')"
            name="dataFrame"
          />
        </div>
        <div class="dataframe-selector__content-item">
          <button
            :disabled="isDataSubmitable || isLoading"
            class="btn btn-default"
            @click="getModelTesting"
          >
            {{ $t('modelTesting.testSubmitBtn') }}
          </button>
        </div>
      </div>
    </div>
    <spinner
      v-if="isLoading"
      :title="$t('editing.loading')"
    />
    <template v-else-if="!error.isError && isContentShow">
      <div class="info-block">
        <model-performance :training-type="modelTrainingType">
          <template slot="description">
            <div class="description description-content">
              <i18n
                path="modelPerformance.performanceDescription"
                tag="span"
              >
                <template #testing_data>
                  <span class="description__highlight">{{ simulatedFrameName }}</span>
                </template>
                <template #testing_data_count>
                  <span class="description__highlight">{{ formatComma(testingResponse.simulateDataCount || testingResponse.modelPerformance.testingCount) }}{{ $t('modelPerformance.record') }}</span>
                </template>
              </i18n>
              <i18n
                v-if="ignoreCount"
                path="modelPerformance.ignoreDescription"
                tag="span"
              >
                <template #ignore_count>
                  <span class="description__highlight">{{ ignoreCount }}{{ $t('modelPerformance.record') }}</span>
                </template>
              </i18n>
              <span
                v-if="modelTrainingType === 'Binary'"
                class="description__highlight"
              >
                {{ $t('modelInfo.threshold') }} = {{ autoRound(modelInfo.threshold) }}
              </span>
            </div>
          </template>
          <template slot="metrics">
            <model-task
              :component-id="testingResponse.metricsId"
              :training-type="modelTrainingType"
            />
          </template>
          <template
            v-if="modelTrainingType === 'Regression'"
            slot="chart"
          >
            <div class="group-title">
              {{ $t('modelPerformance.modelDeviationDistribution') }}
              <remind-popover
                :content="$t('modelPerformance.reminder.modelDeviationDistributionExplain')"
                icon-name="description"
              />
            </div>
            <model-task
              :component-id="testingResponse.residualDistributionId"
              :is-show-toolbox="false"
              :is-show-warning-enable="false"
              height="275px"
              direction="horizontal"
            />
          </template>
          <template
            v-else-if="modelTrainingType === 'Binary'"
            slot="chart"
          >
            <div class="group-title">
              {{ $t('model.confusionMatrix') }}
              <el-popover
                :offset="-60"
                width="548"
                trigger="hover"
                placement="bottom-start"
                popper-class="el-popover--training-reminder"
              >
                <svg-icon
                  slot="reference"
                  style="color: #A7A7A7"
                  icon-class="description"
                />
                <metric-content
                  :metrics-type="modelTrainingType"
                  display-metric="matrix"
                />
              </el-popover>
            </div>
            <model-task
              :component-id="testingResponse.confusionMatrixId"
              :height="300"
              class="confusion-matrix"
            />
          </template>
        </model-performance>
        <div
          v-if="modelTrainingType === 'Regression'"
          class="info-block__group"
        >
          <div class="info-block__title">
            {{ $t('modelPerformance.compareResultPerformance') }}
            <remind-popover
              :content="$t('modelPerformance.reminder.compareResultPerformance')"
              icon-name="description"
              width="250"
            />
          </div>
          <div class="info-block__content">
            <model-task
              v-if="testingResponse.currentDataDistributionComparisonId"
              :component-id="testingResponse.currentDataDistributionComparisonId"
              :is-show-toolbox="false"
              :is-show-warning-enable="false"
              intend="key_result"
            />
          </div>
        </div>
        <div class="info-block__group">
          <div class="info-block__title">
            {{ $t('modelTesting.compareResultDeviation') }}
            <remind-popover
              :content="$t('modelTesting.reminder.compareResultDeviation')"
              icon-name="description"
            />
          </div>
          <template v-if="modelTrainingType === 'Regression'">
            <div class="info-block__content">
              <model-task
                v-if="testingResponse.residualDistributionComparisonId"
                :component-id="testingResponse.residualDistributionComparisonId"
                :is-show-toolbox="false"
                width="70%"
                intend="key_result"
              />
            </div>
          </template>
          <template v-else-if="modelTrainingType === 'Binary'">
            <div class="curve-container">
              <div class="info-block__content curve-container__left">
                <div class="group-title">
                  {{ $t('modelTesting.compareROC') }}
                  <el-popover
                    :offset="-60"
                    width="548"
                    trigger="hover"
                    placement="bottom-start"
                    popper-class="el-popover--training-reminder"
                  >
                    <svg-icon
                      slot="reference"
                      style="color: #A7A7A7"
                      icon-class="description"
                    />
                    <metric-content
                      :metrics-type="modelTrainingType"
                      display-metric="ROC"
                      description-type="Testing"
                    />
                  </el-popover>
                </div>
                <model-task
                  v-if="testingResponse.ROCComparisonTcId"
                  :component-id="testingResponse.ROCComparisonTcId"
                  :is-smooth="true"
                  :is-show-coefficients="true"
                  :is-show-formula="false"
                  :is-show-toolbox="false"
                  height="260px"
                />
              </div>
              <div class="info-block__content curve-container__right">
                <div class="group-title">
                  {{ $t('modelTesting.comparePR') }}
                  <el-popover
                    :offset="-60"
                    width="548"
                    trigger="hover"
                    placement="bottom-start"
                    popper-class="el-popover--training-reminder"
                  >
                    <svg-icon
                      slot="reference"
                      style="color: #A7A7A7"
                      icon-class="description"
                    />
                    <metric-content
                      :metrics-type="modelTrainingType"
                      display-metric="PR"
                      description-type="Testing"
                    />
                  </el-popover>
                </div>
                <model-task
                  v-if="testingResponse.PRComparisonTcId"
                  :component-id="testingResponse.PRComparisonTcId"
                  :is-smooth="true"
                  :is-show-coefficients="true"
                  :is-show-formula="false"
                  :is-show-toolbox="false"
                  height="260px"
                />
              </div>
            </div>
          </template>
        </div>
      </div>
    </template>
    <div
      v-if="error.isError"
      class="info-block__content notice-block notice-block--error"
    >
      <p class="notice-block__title">
        <svg-icon icon-class="exclamation-triangle" />
        {{ $t('modelTesting.errorMessageTitle') }}
      </p>
      <div class="notice-block__content">
        <p>{{ $t('modelTesting.errorMessageContent') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getModelInfo, getRegressionModelTestingTask, getBinaryModelTestingTask } from '@/API/Model'
import { getDataSourceList } from '@/API/DataSource'
import ModelPerformance from './components/ModelPerformance'
import BreadCrumb from './components/BreadCrumb'
import DefaultSelect from '@/components/select/DefaultSelect'
import RemindPopover from '@/components/popover/RemindPopover.vue'
import MetricContent from '../modelTraining/components/MetricContent'

export default {
  name: 'ModelTesting',
  components: {
    BreadCrumb,
    DefaultSelect,
    RemindPopover,
    MetricContent,
    ModelPerformance
  },
  data () {
    return {
      isLoading: false,
      modelInfo: null,
      dataSourceList: null,
      simulatedFrameName: null,
      testingTargetData: {
        source: null,
        frame: null
      },
      testingResponse: null,
      error: {
        isError: false,
        message: null
      },
      modelTrainingType: null,
      ignoreCount: null
    }
  },
  computed: {
    modelId () {
      return this.$route.params.model_id
    },
    groupId () {
      return this.$route.params.group_id
    },
    dataSources () {
      return this.dataSourceList.map((item) => ({
        value: item.id,
        name: item.name
      }))
    },
    dataFrames () {
      if (this.testingTargetData.source === null) return []
      const sourceIndex = this.dataSourceList.map((item) => item.id).indexOf(this.testingTargetData.source)
      return this.dataSourceList[sourceIndex].dataFrames.map((item) => ({
        value: item.id,
        name: item.name
      }))
    },
    isDataSubmitable () {
      return !(this.testingTargetData.source !== null && this.testingTargetData.frame !== null)
    },
    isContentShow () {
      return this.testingResponse !== null
    }
  },
  watch: {
    'testingTargetData.source' () {
      this.testingTargetData.frame = null
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.modelInfo = await getModelInfo(this.modelId)
      this.modelTrainingType = this.lowercaseEachLetterExceptFirst(this.modelInfo.modelTrainingType)
      const rawDataSourceList = await getDataSourceList(this.groupId)
      this.dataSourceList = rawDataSourceList.map((source) => ({
        id: source.id,
        name: source.name,
        dataFrames: source.dataFrames.map((frame) => ({
          id: frame.id,
          name: frame.primaryAlias
        }))
      }
      ))
    },
    async getModelTesting () {
      this.isLoading = true
      this.error.isError = false
      this.error.message = null

      try {
        const testingAPI = {
          Regression: getRegressionModelTestingTask,
          Binary: getBinaryModelTestingTask
        }
        this.testingResponse = await testingAPI[this.modelTrainingType](this.modelId, this.testingTargetData.frame)
        this.ignoreCount = this.modelTrainingType === 'Binary' ? this.testingResponse.modelPerformance.ignoreCount : null
        this.simulatedFrameName = this.dataFrames.filter(frame => frame.value === this.testingTargetData.frame)[0].name
      } catch (e) {
        if (!e.sueess) {
          this.error.isError = true
          this.error.message = e.error.message
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.description {
  font-size: 13px;
  font-weight: 400;
  color: #DDDDDD;
  &:not(:first-of-type) {
    margin-bottom: 16px;
  }
  &__highlight {
    color: #44D2FF;
  }
}

.dataframe-selector {
  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.01em;
    margin: 0 8px 10px;
  }
  &__content {
    display: flex;
    flex-wrap: wrap;
    &-item {
      & + & {
        margin-left: 16px;
      }
    }

    ::v-deep .el-select {
      border-bottom: 1px solid #fff;
    }
  }
}

.notice-block {
  position: relative;
  color: #FFDF6F;
  background-color: rgba(#FFDF6F, 0.2);
  border-radius: 8px;
  &--error {
    color: #FF5C46;
    background-color: rgba(#FF5C46, 0.2);
  }
  &__title {
    font-size: 13px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.1em;
    margin: 0 0 8px;
    .svg-icon {
      width: 12px;
      height: 12px;
      margin-right: 5px;
      display: inline-block;
    }
  }
  &__content {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
  }
  &__tooltip {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    .svg-icon {
      margin-right: 3px;
    }
  }
}

.curve-container {
  display: flex;

  &__left {
    flex: 1 1;
    margin-right: 16px;
  }
  &__right {
    flex: 1 1;
  }
}

.info-block{
    &__group {
      margin-bottom: 24px;
  }
}

::v-deep .group-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1em;
}
</style>
